import { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { useMountEffect } from "@react-hookz/web";
import { observer } from "mobx-react-lite";
import EditReport from "@/components/assessment/report/edit/EditReport";
import DeletedReportLayout from "@/components/assessment/report/edit/config/DeletedReportLayout";
import BuildReportModal from "@/components/assessment/report/edit/modals/BuildReportModal";
import ConfirmModal from "@/components/common/content/ConfirmModal";
import { LoadingModal } from "@/components/common/content/LoadingModal";
import NotPermissable from "@/components/common/content/NotPermissable";
import { ReportStoreContext, UserStoreContext, loggerContext } from "@/store";
import { ReportBuildStatus } from "@/store/report.store";
import { initLogger } from "@/utils";

const logger = initLogger("AssessmentReportSingle", loggerContext);

const AssessmentReportSingle = () => {
  const { id } = useParams();
  const { currentUser } = useContext(UserStoreContext);
  const reportStore = useContext(ReportStoreContext);
  const { hydratedReport, confirmAction, buildResult, reportBuildStatus } = reportStore;

  useMountEffect(() => logger.mount("AssessmentReportSingle"));

  useEffect(() => {
    if (!id || !currentUser) return;
    reportStore.fetchReport(id);
  }, [id]);

  if (!currentUser) return <NotPermissable />;
  if (hydratedReport && !!hydratedReport.report.deletedAt)
    return <DeletedReportLayout report={hydratedReport.report} />;
  if (hydratedReport === null) return <div className="text-gray-900">Could not find assessment report {id}</div>;
  else if (!hydratedReport || hydratedReport.report.id !== id)
    return <div className="text-gray-900 grid place-items-center">Loading...</div>;

  return (
    <div className="text-gray-900 h-full flex flex-col min-w-[600px]">
      <div className="w-full h-0 grow">
        <EditReport hydratedReport={hydratedReport} />
      </div>

      <BuildReportModal />
      {reportBuildStatus === ReportBuildStatus.Building && <LoadingModal subtext={buildResult?.status} />}
      {!!confirmAction && <ConfirmModal {...confirmAction} onClose={() => reportStore.clearConfirmAction()} />}
    </div>
  );
};

export default observer(AssessmentReportSingle);
