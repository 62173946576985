import { format } from "fecha";
import { DateTime } from "luxon";
import {
  ReportLanguage,
  ReportStatus,
  ReportType,
  ReportValueGroup,
  ValueFileSource,
} from "@parallel/vertex/enums/report.enums";
import { ReportStudentDetails } from "@parallel/vertex/types/assessment/assessment.report.types";
import {
  HydratedAssessmentReport,
  AdminReports,
  ReportUpdateRequest,
  ReportId,
  AssessmentReport,
} from "@parallel/vertex/types/report.types";
import { AlertStore } from "@/store/alert.store";
import { UserStore } from "@/store/user.store";
import { AuthorizedBaseAPI } from "./base.api";

export const INTERVIEW_FORM_TYPES = ["caregiver", "student", "teacher"] as const;
export type InterviewFormType = (typeof INTERVIEW_FORM_TYPES)[number];

export type AssessmentReportSourceFile = {
  file: File;
  source: ValueFileSource;
};

export class ReportAPI extends AuthorizedBaseAPI {
  constructor(alertStore: AlertStore, userStore: UserStore) {
    super(alertStore, userStore, { parseTimestamps: true });
  }

  public getByOwner = (ownerUserId: string): Promise<HydratedAssessmentReport[]> =>
    this.instance.get(`/report?startedByUserId=${ownerUserId}`).then(r => r.data);

  public getByAdminUser = (currentUserId: string): Promise<AdminReports> =>
    this.instance.get(`/report/admin/${currentUserId}`).then(r => r.data);

  public get = (id: ReportId): Promise<HydratedAssessmentReport> =>
    this.instance.get(`/report/${id}`).then(r => r.data);

  public create = ({
    clientUserId,
    startedByUserId,
    type,
    language,
    consentSignedAt,
    reviewerUserId,
    reportDueDate,
  }: {
    clientUserId: string;
    startedByUserId: string;
    type: ReportType;
    language: ReportLanguage;
    consentSignedAt?: Date;
    reviewerUserId?: string;
    reportDueDate?: Date;
  }) =>
    this.instance
      .post(`/report/new`, {
        clientUserId,
        startedByUserId,
        dueDateTimestamp: reportDueDate && format(reportDueDate, "isoDateTime"),
        consentSignedAtTimestamp: consentSignedAt && format(consentSignedAt, "isoDateTime"),
        type,
        language,
        reviewerUserId,
      })
      .then(r => r.data);

  public share = (id: string, recipientProviderId: string) =>
    this.instance.post(`/report/${id}/share`, { recipientProviderId }).then(r => r.data);

  public submitTestingPlan = (id: ReportId, formAnswers: Record<string, any>): Promise<void> =>
    this.instance.post(`/report/${id}/testing-plan`, { formAnswers });

  public addInterviewForm = (id: ReportId, formType: InterviewFormType): Promise<AssessmentReport> =>
    this.instance.post(`/report/${id}/interview-form`, { formType }).then(r => r.data);

  public uploadSources = (id: ReportId, reportSourceFiles: AssessmentReportSourceFile[]): Promise<AssessmentReport> => {
    const formData = new FormData();
    reportSourceFiles.forEach(({ file, source }) => formData.append(source, file, file.name));
    return this.instance.post(`/report/${id}/upload-sources`, formData).then(r => r.data);
  };

  public setStatus = (id: ReportId, status: ReportStatus, completedSurvey?: boolean): Promise<AssessmentReport> =>
    this.instance.put(`/report/${id}/status`, { status, completedSurvey }).then(r => r.data);

  public updateReport = (id: ReportId, update: ReportUpdateRequest): Promise<AssessmentReport> =>
    this.instance.put(`/report/${id}`, update).then(r => r.data);

  public resetSourceGroup = (id: ReportId, group: ReportValueGroup) =>
    this.instance.put(`/report/${id}/values/reset`, { group }).then(r => r.data);

  public softDeleteReport = (id: ReportId): Promise<AssessmentReport> =>
    this.instance.delete(`/report/${id}/delete`).then(r => r.data);

  public clearAllSourceGroups = (id: ReportId) => this.instance.put(`/report/${id}/values/clear`).then(r => r.data);

  public downloadPrintableReportForm = (fileKey: string, userId: string, clientUserId: string): Promise<Blob> =>
    this.instance
      .get(`/report/printable-form/${fileKey}/${userId}?forClientUserId=${clientUserId}`, { responseType: "blob" })
      .then(r => r.data);

  public getReportStudentDetails = (studentId: string): Promise<ReportStudentDetails> =>
    this.instance.get(`/report/student/${studentId}`).then(r => {
      const serviceLine = r.data.assessmentServiceLine;
      return serviceLine
        ? {
            ...r.data,
            assessmentServiceLine: {
              ...serviceLine,
              consentSignedAt:
                serviceLine.consentSignedAt && DateTime.fromISO(serviceLine.consentSignedAt).setZone("utc"),
            },
          }
        : r.data;
    });
}
